import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import Menu from '../../components/menuComponent';
import ContactCaptcha from '../../components/contactContainer';

const ContactContainer = ({ location }) => {
  const { pathname } = location;
  const filter = pathname.split('/').filter((pathString) => pathString.length > 0).pop();

  return (
    <Layout
      title="Contact us to ask a question | Wow Interior Design"
      description="For any general enquiry about who we are & what we offer please fill in our form & a member of the team will get straight back to you."
    >
      <Menu />
      <ContactCaptcha formType={filter} />
    </Layout>
  );
};

export default ContactContainer;

ContactContainer.propTypes = {
  location: PropTypes.object.isRequired,
};
